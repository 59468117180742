<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Super Admin Template Actions</h5>
            </div>
            <div class="card">
                <DataTable :value="templateactionlist" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="bb1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Template Actions</h5>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.tc1 }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column field="name" header="Action Name" headerStyle="width: 50%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.tc2 ? data.tc2 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Unique Id" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div> {{ data.tc3 }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <Button label="Edit Event" class="p-button-link" @click="templateActionsRedirect(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';
export default {
    data() {
        return {
            v$: useValidate(),
            templateactionlist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            localSuperAdminActionId: '',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        if (localStorage.localSuperAdminActionId) {
            this.localSuperAdminActionId = localStorage.localSuperAdminActionId;
        }
        this.getAdminTemplateActions();
    },
    watch: {
        localSuperAdminActionId(actionId) {
            localStorage.localSuperAdminActionId = actionId;
        },
    },
    validations() {
        return {
        };
    },
    methods: {
        getAdminTemplateActions(ev) {
            this.loading = true;
            this.ApiService.getSuperAdminTemplateActions(ev).then((data) => {
                if (data.status == 200) {
                    this.templateactionlist = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.templateactionlist = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
        },

        templateActionsRedirect(e) {
            router.push({
                name: "listSuperAdminTemplateEvent",
                params: { actionId: e.tc1 },
            });
            this.localSuperAdminActionId = e.tc1;
        },
    },
};
</script>
<style scoped lang="scss">
.plan-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-free {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-paid {
        background: #ffd8b2;
        color: #805b36;
    }
}

.plan-type-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-standard {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-premium {
        background: #eccfff;
        color: #694382;
    }
}
</style>
